import { TicketsSharedType } from '@/modules/tickets/shared/types';

export namespace Statistics {
  export enum Pages {
    STATISTICS = 'statistics',
    STATISTICS_DETAIL = 'statistics-detail'
  }
  export type sortType = {
    asc: string,
    desc: string,
    '': string
  }
  export type Creators = {
    id: number,
    name:string
  }

  export type CreatorsId = [
    id: number,
  ]
  export interface StatisticsQuery {
    worktableId: number,
    date?: Date | string,
  }

export interface StatisticsSortQuery {
  worktableId: number;
  date: string;
  sort: string;
  sort_type: string;
  filter: string,
}

  export type AttractionStatistic = {
    id: number,
    title: string,
    tickets_completed_count?: number,
    tickets_with_media_count?: number,
    tickets_with_media_count_avisale?: number,
    tickets_with_media_count_presale?: number,
    tickets_before_12_00_count?: number,
    tickets_before_14_00_count?: number,
    tickets_before_16_00_count?: number,
    tickets_before_18_00_count?: number,
    timeslots_free_count?: number
  }

  export interface Dashboard {
    id: number,
    owner_id: number,
    title: string,
    created_at?: string,
    updated_at?: string,
    attractions: AttractionStatistic[]
  }

  export interface History {
    creators: Creators[];
    history: TicketsSharedType[],
    total: {
      ticketsCount: number,
      priceSum: number,
      amountSum: number,
    }
  }

    export type Header = {
      text:string,
      value:string
    }
}

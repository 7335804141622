import lodash_debounce from 'lodash.debounce';
import lodash_clone_deep from 'lodash.clonedeep';
import moment from 'moment';

export const clone_deep = lodash_clone_deep;
export const debounce = lodash_debounce;
export * from './hsl-generator';

export const del_mask = (str: number | string): string => {
  if (!str) {
    return '';
  }
  return str.toString().replace(/[^0-9]/g, '');
};

export const scrollTop = () => window.scrollTo({
  top: 0,
  behavior: 'smooth',
});

export function format_date(input_date: string): string {
  return moment(input_date, 'YYYY-MM-DD').format('DD.MM.YYYY');
}
